import "./App.css";
import GKgif from "./assets/GK_animace.gif";
import DotaceEU from "./assets/Dotace_EU.jpg";

function App() {
  return (
    <div className="wrapper">
      <main className="container">
        <section className="text-wrapper">
          <h1>gabriela knyblova studio</h1>
          <p>
            +420 725 931 611 <br />
            <a href="mailto:studio@gabrielaknyblova.cz">
              studio@gabrielaknyblova.cz
            </a>
          </p>

          <p>
            Můj web je dočasně v rekonstrukci,
            <br class="mobile-break" /> s malými přestávkami už několik let.
            <br />
            Stále na něm však usilovně pracuji. Držme se proto hesla:
            <br />
            Trpělivost přináší růže.
          </p>
        </section>
        <div className="image-wrapper">
          <img src={GKgif} alt="gif" className="img-itself" />
        </div>
      </main>
      <footer className="footer-wrapper">
        <img src={DotaceEU} alt="finacovano_EU" className="grant-eu" />
        <h3>
          Byla jsem finančně podpořena v rámci Operačního programu Spravedlivá
          transformace, projektu{" "}
          <a href="https://www.podnikatelskevouchery.cz/" target="blank">
            <i>Vouchery</i>
          </a>{" "}
          pro podnikatele v Moravskoslezském kraji, reg. č.
          CZ.10.03.01/00/23_016/0000181.
        </h3>
      </footer>
    </div>
  );
}

export default App;
